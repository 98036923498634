import {
  Link,
  Breadcrumbs,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import NextLink from "next/link";
import Image from "next/image";
import { ITheme } from "@lib/themes/types";
import React from "react";
import trackEvent, { GAEventCategory } from "@lib/ga";

export interface BreadcrumbsProps {
  data: Array<{
    name: string;
    href?: string;
  }>;
}

const useStyles = makeStyles((theme: ITheme) => ({
  navLink: {
    zIndex: 5,
    "&.MuiTypography-colorTextSecondary": {
      color: theme.palette.text.disabled,
    },
  },
  separator: {
    marginLeft: 0,
    marginRight: 0,
  },
  li: {
    zIndex: 10,
    "& > a": {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}));

export default function BreadcrumbsComponent(props: BreadcrumbsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Breadcrumbs
      separator={
        <Box display="flex" p="8px 9.5px">
          <Image
            src="/chevron-right-icon.svg"
            alt="separator"
            width={5}
            height={8}
          />
        </Box>
      }
      classes={{ separator: classes.separator, li: classes.li }}
      aria-label="breadcrumb">
      {props.data.map((item, index) => {
        const isCurrent = index === props.data.length - 1;
        if ("href" in item === true) {
          return (
            <NextLink href={item.href} key={item.href}>
              <Link
                className={classes.navLink}
                color="textSecondary"
                variant="caption"
                aria-current={isCurrent ? "page" : undefined}
                href={item.href}
                fontWeight="600"
                onClick={() =>
                  trackEvent(
                    {
                      action: item.name,
                      category: GAEventCategory.Breadcrumb,
                      label: item.name,
                    },
                    isMobile,
                  )
                }>
                {item.name}
              </Link>
            </NextLink>
          );
        } else {
          return (
            <Typography
              className={classes.navLink}
              // color={isCurrent ? "textPrimary" : "textSecondary"}
              color="textSecondary"
              variant="caption"
              aria-current={isCurrent ? "page" : undefined}
              fontWeight="600">
              {item.name}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}
